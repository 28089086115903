@import "constants.module.scss";

html {
  overflow: hidden;
  font-weight: 400;
  font-family: Lato;
  font-size: 14px;
}

body {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.centered {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-btn-icon-only {
  > .anticon {
    display: inline-block;
  }
}

.ant-checkbox-inner {
  border-radius: 4px;
}

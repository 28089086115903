$sm: 768px;
$md: 992px;
$lg: 1440px;

$primary: #7a54f6;
$background: #f6f8fa;
$error: #8b0000;
$border: #f0f0f0;

$elevation-sm: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$elevation: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$elevation-lg: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

:export {
  primary: $primary;
  background: $background;
  error: $error;
}

@mixin multiline {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin panel {
  width: 20rem;
  box-shadow: $elevation;
  display: flex;
  flex-direction: column;
  :global .ant-card-body {
    display: flex;
    flex-direction: column;
  }
}
